<div class="controls">
  <div class="left-controls">
    <button mat-icon-button (click)="onPrevious()">
      <fc-icon icon="icon-arrow_left"></fc-icon>
    </button>
    <button mat-icon-button (click)="onNext()">
      <fc-icon icon="icon-arrow_right"></fc-icon>
    </button>
    <button
      mat-button
      #menuTrigger="matMenuTrigger"
      class="month-select"
      [matMenuTriggerFor]="menu"
    >
      {{ selectedDate.format('MMMM YYYY') }}
      <mat-icon
        class="month-select-icon"
        matDatepickerToggleIcon
        fontIcon="icon-arrow_drop_down"
      ></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="custom-calendar">
        <div
          *ngFor="let month of months"
          class="month"
          [ngClass]="{
            current: getCurrentMonth(month.date),
            selected: getSelectedMonth(month.date),
          }"
          (click)="selectMonth(month.date)"
        >
          {{ month.name }}
        </div>
      </div>
    </mat-menu>
  </div>
  <div class="right-controls">
    <div class="user-search-container">
      <fc-icon-button (click)="activeSearch = true">
        <fc-icon icon="icon-search"></fc-icon>
      </fc-icon-button>
      <fc-table-search-header
        (searchClosed)="onCloseSearch()"
        (searchEmitter)="searchChange.emit($event)"
        top="0"
        *ngIf="activeSearch"
        class="table-search-header"
      ></fc-table-search-header>
    </div>
    <fc-text-button
      color="primary"
      class="open-edit-container"
      (click)="openEditFunction(null)"
    >
      <div class="open-edit">
        <fc-icon color="primary600" icon="icon-add"></fc-icon>
        <span class="open-edit-button-text">Assign {{ itemType }}</span>
      </div>
    </fc-text-button>
  </div>
</div>
